<template>
    <div class="Cooperative">
        <div class="core">
            <div class="titles">
                <h3>{{$t('CooperativeExhibitors.Title')}}</h3>
            </div>
            <div class="Cooperative_main">
                <div class="swiper-father">
                    <div class="swiper-container Exhibitorsdirectory">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide swiper" v-for="info in newData" :key="info[0].id">
                                <div class="list" v-for="item in info" :key="item.id">
                                    <a class="item-container" :href="item.webSite">
                                        <img v-lazy="item.logo" alt="">
                                        <p>{{item.name}}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 我们把swiper 的箭头放到swiper 外面 -->
                    <div class="swiper-button-prev" style="top:60%"></div>
                    <div class="swiper-button-next" style="top:60%"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {registerCompany} from "@/publicApi/company";

    export default {
        data() {
            return {
                prev: {
                    backgroundImage: "url(/images/Exhibitorsservice/left.png)",
                    backgroundRepeat: "no-repeat",
                },
                next: {
                    backgroundImage: "url(/images/Exhibitorsservice/right.png)",
                    backgroundRepeat: "no-repeat",
                },
                newData: []
            }
        },
        mounted() {
            this.getCurrentregisterCompany()
            var mySwiper = new Swiper('.Exhibitorsdirectory', {
                direction: 'horizontal',
                loop: false,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        },
        methods: {
            async getCurrentregisterCompany() {
                this.newData = []
                let {
                    data,
                    status,
                    msg
                } = await registerCompany({
                    lang: this.$transformAPILang(),
                })
                for (var i = 0; i < data.length; i += 48) {
                    this.newData.push(data.slice(i, i + 48))

                }
                // console.log(this.newData)
            },
            Towardstheleft() {
                this.mySwiper.slidePrev();
            },
            Towardstheright() {
                this.mySwiper.slideNext()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 61%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        top: 61%;
        left: -72px;
        right: auto;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        top: 61%;
        right: -72px;
        left: auto;
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 61%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .Cooperative {
        width: 100%;
        background: url('../../../assets/images/Exhibitorsservice/bg_hezuozhanshang@2x.jpg') center no-repeat;
        background-size: cover;

    }

    .core {
        margin: auto;
        position: relative;

        @include design-width-setting;
    }

    .titles {
        @include titles();

        line-height: 32px;
        margin-bottom: 42px;
        padding-bottom: 3px;
        padding-top: 50px;

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
        }
    }

    .Cooperative_main {
        overflow: hidden;

        .swiper {
            overflow: hidden;

            .list {
                width: 12.5%;
                @include sm {
                    width: 50%;
                }
                min-width: 148px;
                // margin-right: 4px;
                padding: 0 2px;
                box-sizing: border-box;
                margin-bottom: 10px;
                float: left;
                overflow: hidden;

                .item-container {
                    background: #ffffff;
                    height: 156px;
                    padding-top: 20px;
                    box-sizing: border-box;
                    display: block;
                }

                img {
                    /*width: 68px;*/
                    /*height: 68px;*/
                    width: 100px;
                    height: 100px;
                    display: block;
                    margin: auto;
                }

                p {
                    font-weight: 400;
                    color: rgba(34, 24, 21, 1);
                    font-size: 12px;
                    width: 100px;
                    text-align: center;
                    display: block;
                    margin: auto;
                    margin-top: 10px;
                }
            }
        }
    }

    .Towardstheleft {
        width: 22px;
        height: 44px;
        position: absolute;
        left: -90px;
        top: 430px;
    }

    .Towardstheright {
        width: 22px;
        height: 44px;
        position: absolute;
        right: -90px;
        top: 430px;
    }

    .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        margin-top: -11px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 41px;
        margin-top: -23px;
        margin-right: 10px;
        z-index: 10;
        cursor: pointer;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .nav-arrow {
        opacity: 0.5;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    @include md {
        .swiper-button-prev, .swiper-button-next {
            display: none;
        }
    }
</style>