<template>
    <div>
        
         <!-- <Header/>  -->
        <Bannern/>
        <Exhibition id="exhibition"/>
        <Floorplan id="Floorplan"/>
        <Cooperativeexhibitors id="CooperativeExhibitors"/>
        <Sponsor id="Sponsor"/>
        <DownloadCenter id="DownloadCenter"/>
        <Transportations id="traffic"/>
        <Footer/>
    </div>
</template>
<script>
import Header from '../../components/header' //头部
import Bannern from './children/Rotationcharts'  //焦点图
import Exhibition from './children/exhibition' //申请参展
import Floorplan from './children/Floorplan' //  展位图
import DownloadCenter from './children/DownloadCenter' // 下载中心
import Cooperativeexhibitors from './children/Cooperativeexhibitors' // 合作展商
import Sponsor from './children/Sponsor' // 我要赞助
import Transportations from './children/Transportations' // 交通住宿
import Footer from '../home/children/Footer' //页脚
export default {
    components:{
        Header,
        Bannern,
        Exhibition,
        Floorplan,
        DownloadCenter,
        Cooperativeexhibitors,
        Sponsor,
        Footer,
        Transportations

    }
    
}
</script>

<style lang="less" scoped>
html, body { min-width:100%;}
@media only screen and (min-width:100%) {
    html, body { min-width:100%; }
}
</style>

