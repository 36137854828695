var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exhibition", style: _vm.bgimg }, [
    _c("div", { staticClass: "Newedition" }, [
      _c("div", { staticClass: "titles" }, [
        _c(
          "h3",
          {
            staticStyle: {
              "font-size": "26px",
              "font-weight": "600",
              color: "#333333"
            }
          },
          [_vm._v(_vm._s(_vm.$t("ApplicationExhibition.Title")))]
        )
      ])
    ]),
    _c("div", { staticClass: "Mainbox" }, [
      _c("div", { staticClass: "Technological" }, [
        _c("ul", { staticClass: "piclist" }, [
          _c("li", { staticClass: "list_item" }, [
            _c("img", {
              attrs: {
                src: "/images/Exhibitorsservice/icon_tijiaoshengqing@2x.png",
                alt: ""
              }
            }),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("ApplicationExhibition.ApplicationSubmission"))
              )
            ])
          ]),
          _c("li", { staticClass: "list_item" }, [
            _c("img", {
              attrs: {
                src: "/images/Exhibitorsservice/icon_shangwuqieren@2x.png",
                alt: ""
              }
            }),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("ApplicationExhibition.BusinessConfirmation"))
              )
            ])
          ]),
          _c("li", { staticClass: "list_item" }, [
            _c("img", {
              attrs: {
                src: "/images/Exhibitorsservice/icon_chenggongchanzhan@2x.png",
                alt: ""
              }
            }),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$t("ApplicationExhibition.SuccessfulExhibition"))
              )
            ])
          ])
        ])
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "Applications", style: _vm.zgimg },
        [
          _c("keep-alive", [
            _c("div", { staticClass: "box-ssing" }, [
              _c("div", { staticClass: "label-root" }, [
                _c("label", { attrs: { for: "company" } }, [
                  _c("div", { staticClass: "label-container" }, [
                    _c("span", [
                      _vm._v(
                        "* " + _vm._s(_vm.$t("ApplicationExhibition.Company"))
                      )
                    ]),
                    _c("div", { staticClass: "input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.application.company,
                            expression: "application.company"
                          }
                        ],
                        staticClass: "input",
                        class: { showborder: _vm.company == true },
                        attrs: { type: "text", name: "", id: "company" },
                        domProps: { value: _vm.application.company },
                        on: {
                          blur: _vm.changeNumFun,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.application,
                              "company",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "after" })
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "label-root" }, [
                _c("label", { attrs: { for: "Contacts" } }, [
                  _c("div", { staticClass: "label-container" }, [
                    _c("span", [
                      _vm._v(
                        "* " + _vm._s(_vm.$t("ApplicationExhibition.Contacts"))
                      )
                    ]),
                    _c("div", { staticClass: "input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.application.contacts,
                            expression: "application.contacts"
                          }
                        ],
                        staticClass: "input",
                        class: { showborder: _vm.contacts == true },
                        attrs: { type: "text", name: "", id: "Contacts" },
                        domProps: { value: _vm.application.contacts },
                        on: {
                          blur: _vm.changeNumContacts,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.application,
                              "contacts",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "after" })
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "label-root" }, [
                _c("label", { attrs: { for: "Mobilephone" } }, [
                  _c("div", { staticClass: "label-container" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$route.params.lang == "zh" ? "*" : "") +
                          " " +
                          _vm._s(_vm.$t("ApplicationExhibition.Mobilephone"))
                      )
                    ]),
                    _c("div", { staticClass: "input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.application.mobilephone,
                            expression: "application.mobilephone"
                          }
                        ],
                        staticClass: "input",
                        class: { showborder: _vm.mobilephone == true },
                        attrs: { type: "text", name: "", id: "Mobilephone" },
                        domProps: { value: _vm.application.mobilephone },
                        on: {
                          blur: _vm.changeNumMobilephone,
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.application,
                              "mobilephone",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("div", { staticClass: "after" })
                    ])
                  ])
                ])
              ]),
              _vm.$route.params.lang == "en"
                ? _c("div", { staticClass: "label-root" }, [
                    _c("label", { attrs: { for: "mailbox" } }, [
                      _c("div", { staticClass: "label-container" }, [
                        _c("span", [
                          _vm._v(
                            "* " +
                              _vm._s(_vm.$t("ApplicationExhibition.Mailbox"))
                          )
                        ]),
                        _c("div", { staticClass: "input-container" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.application.mailbox,
                                expression: "application.mailbox"
                              }
                            ],
                            staticClass: "input",
                            class: { showborder: _vm.thismailbox == true },
                            attrs: { type: "text", name: "", id: "mailbox" },
                            domProps: { value: _vm.application.mailbox },
                            on: {
                              blur: _vm.changemailbox,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.application,
                                  "mailbox",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "after" })
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "label-root" }, [
                _c(
                  "label",
                  {
                    staticClass: "VerificationCode",
                    attrs: { for: "VerificationCode" }
                  },
                  [
                    _c("div", { staticClass: "label-container" }, [
                      _c("span", [
                        _vm._v(
                          "* " +
                            _vm._s(
                              _vm.$t("ApplicationExhibition.VerificationCode")
                            )
                        )
                      ]),
                      _c("div", { staticClass: "input-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.application.verificationCode,
                              expression: "application.verificationCode"
                            }
                          ],
                          staticClass: "VerificationCode input",
                          class: { showborder: _vm.verificationCode == true },
                          attrs: {
                            type: "text",
                            name: "",
                            id: "VerificationCode"
                          },
                          domProps: { value: _vm.application.verificationCode },
                          on: {
                            blur: _vm.changeNumVerificationCode,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.application,
                                "verificationCode",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("div", { staticClass: "after" })
                      ])
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.codeMsg,
                          expression: "codeMsg"
                        }
                      ],
                      staticClass: "btn input",
                      attrs: { type: "button", disabled: _vm.codeDisabled },
                      domProps: { value: _vm.codeMsg },
                      on: {
                        click: _vm.Verification,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.codeMsg = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ]),
              _vm.$route.params.lang == "zh"
                ? _c("div", { staticClass: "label-root" }, [
                    _c("label", { attrs: { for: "mailbox" } }, [
                      _c("div", { staticClass: "label-container" }, [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("ApplicationExhibition.Mailbox"))
                          )
                        ]),
                        _c("div", { staticClass: "input-container" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.application.mailbox,
                                expression: "application.mailbox"
                              }
                            ],
                            staticClass: "input",
                            class: { showborder: _vm.thismailbox == true },
                            attrs: { type: "text", name: "", id: "mailbox" },
                            domProps: { value: _vm.application.mailbox },
                            on: {
                              blur: _vm.changemailbox,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.application,
                                  "mailbox",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "after" })
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "Apply",
                  on: { click: _vm.ApplicationExhibition }
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("ApplicationExhibition.Submission")) +
                      "\n                    "
                  )
                ]
              )
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Progressbar" }, [
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "imgUrl", staticStyle: { margin: "0 26px" } }, [
        _c("img", {
          attrs: {
            src: "/images/Exhibitorsservice/icon_gou_disabled@2x.png",
            alt: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }