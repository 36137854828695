<template>
    <div class="booth">
        <div class="centre">
            <div class="titles">
                <h3>{{$t('Floorplan.Title')}}</h3>
            </div>
        </div>
        <div class="booth_main">
            <app-swiper :enablePagination="true" style="width:100% " class="app_height">
                <app-swiper-item class="box_Warp">
                    <img :src="pickerMain[3]" alt
                         style="display: block;margin: auto"/>
                </app-swiper-item>
                <app-swiper-item class="box_Warp">
                    <img :src="pickerMain[4]" alt
                         style="display: block;margin: auto "/>
                </app-swiper-item>
                <app-swiper-item class="box_Warp">
                    <img :src="pickerMain[5]" alt
                         style="display: block;margin: auto"/>
                </app-swiper-item>
            </app-swiper>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
    };
</script>
<style lang="scss" scoped>
    .swiper-pagination-bullets {
        bottom: 0;
    }

    .swiper-wrapper {
        margin-bottom: 50px;
    }

    .booth {
        width: 100%;
        padding-bottom: 30px;
    }

    .centre {
        margin: auto;
        padding-bottom: 30px;
        @include design-width-setting();
    }

    .titles {
        @include titles();

        line-height: 32px;
        margin-bottom: 10px;
        padding-bottom: 3px;
        margin-top: 10px;
        /*padding-top: 100px;*/

        h3 {
            font-size: 26px;
            font-weight: 600;
            color: #333333;
        }
    }

    .booth_main {
        padding: 34px 0 34px 0;
        margin: auto;
        width: 100%;

        .app_height {
            width: 100%;

            .box_Warp {
                img {
                    display: block;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    .swiper-pagination {
        display: block;
        width: 1278px;
        margin: auto;
    }
</style>