var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "booth" }, [
    _c("div", { staticClass: "centre" }, [
      _c("div", { staticClass: "titles" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("Floorplan.Title")))])
      ])
    ]),
    _c(
      "div",
      { staticClass: "booth_main" },
      [
        _c(
          "app-swiper",
          {
            staticClass: "app_height",
            staticStyle: { width: "100%" },
            attrs: { enablePagination: true }
          },
          [
            _c("app-swiper-item", { staticClass: "box_Warp" }, [
              _c("img", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: { src: _vm.pickerMain[3], alt: "" }
              })
            ]),
            _c("app-swiper-item", { staticClass: "box_Warp" }, [
              _c("img", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: { src: _vm.pickerMain[4], alt: "" }
              })
            ]),
            _c("app-swiper-item", { staticClass: "box_Warp" }, [
              _c("img", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: { src: _vm.pickerMain[5], alt: "" }
              })
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }