<template>
    <div class="Sponsor" :style="bgimgs ">
        <div class="Sponsor_main">
            <div class="title">
                <h3 class="">{{$t('Sponsor.Title')}}</h3>
            </div>
            <div class="Sponsorshipcontent">
                <div class="left">
                    <img src="/images/76D6B434.jpg" alt="">
                </div>
                <div class="right">
                    <div class="Advertisement">
                        <div class="main_right">
                            <h4>{{$t('Sponsor.AdvertisementTitle')}}</h4>
                            <p>
                                <span> {{$t('Sponsor.Project')}}</span> <br>
                                <span> {{$t('Sponsor.Number')}}</span><br>
                                <span>{{$t('Sponsor.Price')}}</span><br>
                                <span>{{$t('Sponsor.Explain')}}</span><br>
                                <span>{{$t('Sponsor.Enterprise')}} </span>

                            </p>
                            <router-link :to="`/${this.$route.params.lang}/Sponsorship`" class="immediately">
                                <span>{{$t('Sponsor.Immediately')}}</span>
                            </router-link>
                            <div class="block">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                bgimgs: {
                    backgroundImage: "url(/images/img_bg_2@2x.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: '#F6f6f6'
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Sponsor {
        width: 100%;
        background-position-y: 360px;

        .Sponsor_main {
            margin: auto;
            padding-top: 30px;
            @include design-width-setting;

            .title {
                @include titles();

                margin-bottom: 42px;
                padding-bottom: 3px;
                line-height: 32px;

                h3 {
                    font-size: 26px;
                    font-weight: 600;
                    color: #333;

                }
            }

            .Sponsorshipcontent {
                position: relative;

                .left {
                    width: 888px;
                    height: 500px;
                    z-index: 1;
                    position: relative;

                    img {
                        width: 100%;

                        z-index: 20;
                    }
                }

                .right {
                    position: absolute;
                    right: 195px;
                    top: -24px;
                    border: 6px solid #cccccc;
                    width: 414px;

                    //右侧栏
                    .Advertisement {
                        width: 310px;
                        max-width: 100%;
                        min-height: 370px;
                        background: rgba(255, 255, 255, 1);
                        border: 3px solid rgba(102, 102, 102, 1);
                        position: absolute;
                        right: -114px;
                        top: 44px;
                        z-index: 1;

                        .main_right {
                            padding: 50px 40px 0 52px;
                            box-sizing: border-box;

                            h4 {
                                font-weight: bold;
                                color: rgba(102, 102, 102, 1);
                                font-size: 18px;
                            }

                            p {
                                font-size: 14px;
                                font-weight: 400;
                                color: rgba(51, 51, 51, 1);
                                margin-top: 18px;
                                line-height: 22px;
                            }

                            .immediately {
                                display: block;
                                width: 168px;
                                height: 42px;
                                background: rgba(250, 210, 20, 1);
                                font-weight: bold;
                                color: rgba(255, 255, 255, 1);
                                margin: 40px auto 0;
                                text-align: center;
                                line-height: 21px;
                                
                                span{
                                    display: inline-block;
                                }
                                
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    position: relative;
                                    font-size: 0;
                                    width: 0;
                                    height: 100%;
                                    vertical-align: middle;
                                }
                            }

                            .block {
                                width: 50px;
                                height: 50px;
                                border: 3px solid rgba(102, 102, 102, 1);
                                position: absolute;
                                right: -24px;
                                top: -27px;

                                @include sm {
                                    display: none;
                                }
                            }
                        }
                    }
                }


                @include md {
                    .left {
                        height: auto;
                        width: 100%;
                    }

                    .right {
                        position: relative;
                        border: none;
                        right: 0;
                        width: 100%;
                        left: 0;
                        top: 0;

                        .Advertisement {
                            position: relative;
                            margin: 0 auto;
                            left: 0;
                        }
                    }
                }

                @include sm {
                    .left {
                        height: auto;
                        width: 100%;
                    }

                    .right {
                        position: relative;
                        border: none;
                        right: 0;
                        width: 100%;
                        left: 0;
                        top: 0;

                        .Advertisement {
                            position: relative;
                            margin: 0 auto;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

</style>