var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Sponsor", style: _vm.bgimgs }, [
    _c("div", { staticClass: "Sponsor_main" }, [
      _c("div", { staticClass: "title" }, [
        _c("h3", {}, [_vm._v(_vm._s(_vm.$t("Sponsor.Title")))])
      ]),
      _c("div", { staticClass: "Sponsorshipcontent" }, [
        _vm._m(0),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "Advertisement" }, [
            _c(
              "div",
              { staticClass: "main_right" },
              [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("Sponsor.AdvertisementTitle")))
                ]),
                _c("p", [
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("Sponsor.Project")))]),
                  _c("br"),
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("Sponsor.Number")))]),
                  _c("br"),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Sponsor.Price")))]),
                  _c("br"),
                  _c("span", [_vm._v(_vm._s(_vm.$t("Sponsor.Explain")))]),
                  _c("br"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Sponsor.Enterprise")) + " ")
                  ])
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "immediately",
                    attrs: {
                      to: "/" + this.$route.params.lang + "/Sponsorship"
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("Sponsor.Immediately")))])]
                ),
                _c("div", { staticClass: "block" })
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("img", { attrs: { src: "/images/76D6B434.jpg", alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }