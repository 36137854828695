<template>
    <div class="DownloadCenter">
        <div class="centre">
            <div class="title">
                <h3>{{$t('DownloadCenter.Title')}}</h3>
            </div>
            <div class="Downloadlist">
                <ul class="list">
                    <li class="list_item" style=" background: #F6F6F6;" v-for="item in files">
                        <div class="titles">
                            <span>{{item.name}}</span>
                        </div>
                        <div class="time">
                            <span>2019-09-01</span>
                        </div>
                        <div class="Size">
                            <span>{{item.size}}</span>
                        </div>
                        <div class="download">
                            <a :href="item.url" target="_blank"><img src="/images/Exhibitorsservice/icon_download@2x.png" alt/></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                files: [
                    {
                        name: '2020（广州）国际工程机械·建筑设备贸易及服务展览会参展申请表.docx',
                        size: '70kb',
                        url: 'http://static.conmart.cn/expo/files/2020%E4%B8%AD%E5%9B%BD%EF%BC%88%E5%B9%BF%E5%B7%9E%EF%BC%89%E5%9B%BD%E9%99%85%E5%B7%A5%E7%A8%8B%E6%9C%BA%E6%A2%B0%C2%B7%E5%BB%BA%E7%AD%91%E8%AE%BE%E5%A4%87%E8%B4%B8%E6%98%93%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%B1%95%E8%A7%88%E4%BC%9A%E5%8F%82%E5%B1%95%E7%94%B3%E8%AF%B7%E8%A1%A8.docx'
                    },
                    {
                        name: '全部展馆图10.28.pdf',
                        size: '652kb',
                        url: 'http://static.conmart.cn/expo/files/%E5%85%A8%E9%83%A8%E5%B1%95%E9%A6%86%E5%9B%BE10.28.pdf'
                    },
                    {
                        name: '2019康马展回顾展望.jpg',
                        size: '1.7mb',
                        url: 'https://static.conmart.cn/expo/files/2019%E5%BA%B7%E9%A9%AC%E5%B1%95%E5%9B%9E%E9%A1%BE%E5%B1%95%E6%9C%9B.jpg'
                    },
                    {
                        name: '展商列表2019.pdf',
                        size: '265kb',
                        url: 'https://static.conmart.cn/expo/files/%E5%B1%95%E5%95%86%E5%88%97%E8%A1%A82019.pdf'
                    },
                    {
                        name: '康马展服务手册2019.pdf',
                        size: '5.4mb',
                        url: 'https://static.conmart.cn/expo/files/%E5%BA%B7%E9%A9%AC%E5%B1%95%E6%9C%8D%E5%8A%A1%E6%89%8B%E5%86%8C2019.pdf'
                    },
                    {
                        name: '康马展邀请函2020.pdf',
                        size: '3.0mb',
                        url: 'https://static.conmart.cn/expo/files/%E5%BA%B7%E9%A9%AC%E5%B1%95%E9%82%80%E8%AF%B7%E5%87%BD2020.pdf'
                    },
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>
    .DownloadCenter {
        width: 100%;
        background: #F6F6F6;

        .centre {
            margin: auto;
            @include design-width-setting;

            .title {
                @include titles();

                margin-bottom: 42px;
                padding-bottom: 3px;
                line-height: 32px;
                padding-top: 100px;

                h3 {
                    font-size: 26px;
                    font-weight: 600;
                    color: #333333;
                }
            }

            .Downloadlist {
                width: 100%;

                .list {
                    width: 100%;
                    border: 5px solid #D8D8D8;

                    .list_item {
                        display: flex;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        padding: 10px 0;
                        line-height: 18px;

                        .titles {
                            padding: 0 0 0 30px;
                            box-sizing: border-box;
                            width: 766px;
                            border-right: 1px solid #D8D8D8;

                            p {
                                font-weight: 400;
                                color: rgba(51, 51, 51, 1);
                                display: inline-block;
                            }

                            @include sm {
                                padding-left: 5px;

                                span {
                                    font-size: 12px;
                                }
                            }
                        }

                        .time {
                            width: 206px;
                            border-right: 1px solid #D8D8D8;
                            text-align: center;

                            @include sm {
                                font-size: 12px;
                            }
                        }

                        .Size {
                            width: 180px;
                            border-right: 1px solid #D8D8D8;
                            text-align: center;

                            @include sm {
                                font-size: 12px;
                            }
                        }

                        .download {
                            width: 166px;
                            text-align: center;

                            @include sm {
                                width: 80px;
                            }
                        }

                        &:nth-child(even) {
                            background: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
</style>
