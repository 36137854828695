var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DownloadCenter" }, [
    _c("div", { staticClass: "centre" }, [
      _c("div", { staticClass: "title" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("DownloadCenter.Title")))])
      ]),
      _c("div", { staticClass: "Downloadlist" }, [
        _c(
          "ul",
          { staticClass: "list" },
          _vm._l(_vm.files, function(item) {
            return _c(
              "li",
              {
                staticClass: "list_item",
                staticStyle: { background: "#F6F6F6" }
              },
              [
                _c("div", { staticClass: "titles" }, [
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]),
                _vm._m(0, true),
                _c("div", { staticClass: "Size" }, [
                  _c("span", [_vm._v(_vm._s(item.size))])
                ]),
                _c("div", { staticClass: "download" }, [
                  _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                    _c("img", {
                      attrs: {
                        src: "/images/Exhibitorsservice/icon_download@2x.png",
                        alt: ""
                      }
                    })
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time" }, [
      _c("span", [_vm._v("2019-09-01")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }