var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Cooperative" }, [
    _c("div", { staticClass: "core" }, [
      _c("div", { staticClass: "titles" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("CooperativeExhibitors.Title")))])
      ]),
      _c("div", { staticClass: "Cooperative_main" }, [
        _c("div", { staticClass: "swiper-father" }, [
          _c("div", { staticClass: "swiper-container Exhibitorsdirectory" }, [
            _c(
              "div",
              { staticClass: "swiper-wrapper" },
              _vm._l(_vm.newData, function(info) {
                return _c(
                  "div",
                  { key: info[0].id, staticClass: "swiper-slide swiper" },
                  _vm._l(info, function(item) {
                    return _c("div", { key: item.id, staticClass: "list" }, [
                      _c(
                        "a",
                        {
                          staticClass: "item-container",
                          attrs: { href: item.webSite }
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "lazy",
                                rawName: "v-lazy",
                                value: item.logo,
                                expression: "item.logo"
                              }
                            ],
                            attrs: { alt: "" }
                          }),
                          _c("p", [_vm._v(_vm._s(item.name))])
                        ]
                      )
                    ])
                  }),
                  0
                )
              }),
              0
            )
          ]),
          _c("div", {
            staticClass: "swiper-button-prev",
            staticStyle: { top: "60%" }
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            staticStyle: { top: "60%" }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }